/**
 * Helper methods for auth0 sdk
 */

// TODO: Fix cyclic dependency
/* eslint-disable import/no-cycle */
import auth0 from 'auth0-js';
import { platformInfo } from 'utils/platformInfo';
import * as crypto from 'crypto';
import decode from 'jwt-decode';
import moment from 'moment';
import { get, isEmpty, throttle } from 'lodash';
import { stringify } from 'tiny-querystring';
import { Network } from '@capacitor/network';
import retry from 'async-retry';
import logoutReasons from 'utils/auth/logoutReasons';
import storeKeys from 'utils/storage/store-keys';
import audienceTypes from 'assets/data/audienceTypes';
import { getTwilioClient, setTwilioClient } from 'utils/twilio/twilio.utils';
import config from '../../config';
// store
import resetRootState from '../../store/actions/ResetRootStateAction';
// utils
import logger from '../logger';
import { getSessionId } from '../logger/logger';
import storage from '../storage';
import { getParsedValue } from '../storage/helpers';
import {
  bailIfErrorNotRetryable,
  getRetryConfig,
  isLoggedIn,
  setAuth0IdFromToken,
  setUserDetails
} from './helpers';
import { isNativePlatform } from '../capacitor';
import history from '../history';
import { promiseQueue, PromiseQueue } from '../promiseQueue';

const ACCESS_TOKEN_KEY = 'auth0_access_token';
const ID_TOKEN_KEY = 'auth0_id_token';
const REFRESH_TOKEN_KEY = 'auth0_refresh_token';
const VERIFIER_KEY = 'verifier';
const namespace = 'http://wayforward_';
const LAST_UPDATED_TIMESTAMP_KEY = 'last_updated_timestamp';
const LOGGED_IN_TIMESTAMP = 'logged_in_timestamp';
const ANALYTICS_SESSION_DETAILS = 'analyticsSessionDetails';
const LAST_ACTIVE_TIME = 'lastActiveTime';
const BREATHING_EXERCISE_PROGRESS = 'breathingExerciseProgress';
const noConnectionQueue = new PromiseQueue({
  concurrency: 1,
  autoStart: false
});

// config
const {
  tokenTimeoutDuration,
  tokenInspectionInterval,
  refreshTokenReuseInterval,
  refreshTokenLifeTime,
  appVersion
} = config;

class Auth {
  static async setAccessToken(accessToken) {
    await storage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  static async setRefreshToken(refreshToken) {
    if (!isEmpty(refreshToken)) {
      await storage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }
  }

  static async setLastUpdatedTimestamp(timestamp) {
    if (!isEmpty(timestamp)) {
      await storage.setItem(LAST_UPDATED_TIMESTAMP_KEY, timestamp);
    } else {
      logger.error(
        'Value to be used for setting last_updated_timestamp is empty',
        'auth.setLastUpdatedTimestamp'
      );
    }
  }

  static async setLogggedInTime(timestamp) {
    if (!isEmpty(timestamp)) {
      await storage.setItem(LOGGED_IN_TIMESTAMP, timestamp);
    } else {
      logger.error(
        'Value to be used for setting logged_in_timestamp is empty',
        'auth.setLogggedInTime'
      );
    }
  }

  static getLastUpdatedTimestamp() {
    return storage.getItem(LAST_UPDATED_TIMESTAMP_KEY);
  }

  static getLogggedInTime() {
    return storage.getItem(LOGGED_IN_TIMESTAMP);
  }

  static getAccessToken() {
    return storage.getItem(ACCESS_TOKEN_KEY);
  }

  static getRefreshToken() {
    return storage.getItem(REFRESH_TOKEN_KEY);
  }

  static async setIdToken(idToken) {
    await storage.setItem(ID_TOKEN_KEY, idToken);
  }

  static getIdToken() {
    return storage.getItem(ID_TOKEN_KEY);
  }

  static getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  }

  static async clearAccessToken() {
    await storage.removeItem(ACCESS_TOKEN_KEY);
  }

  static async clearRefreshToken() {
    const refreshToken = await storage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken) {
      await storage.removeItem(REFRESH_TOKEN_KEY);
      const { default: authApi } = await import('../../api/auth');
      authApi.revokeRefreshToken(refreshToken);
    }
  }

  static async clearLogggedInTime() {
    await storage.removeItem(LOGGED_IN_TIMESTAMP);
  }

  static async clearBreathingExerciseProgress() {
    await storage.removeItem(BREATHING_EXERCISE_PROGRESS);
  }

  static async clearLastUpdatedTimestamp() {
    await storage.removeItem(LAST_UPDATED_TIMESTAMP_KEY);
  }

  static async clearIdToken() {
    await storage.removeItem(ID_TOKEN_KEY);
  }

  static async clearAnalyticsSessionData() {
    await storage.removeItem(ANALYTICS_SESSION_DETAILS);
  }

  static async clearLastActiveTimestamp() {
    let lastActiveTime = await storage.getItem(LAST_ACTIVE_TIME);
    const currentTimestamp = Date.now();
    const timeDiffInMillisecond = lastActiveTime
      ? (currentTimestamp - lastActiveTime) / (1000 * 60)
      : '00';
    lastActiveTime = lastActiveTime ? new Date(Number(lastActiveTime)) : '';
    logger.info(
      `Timeout: Clear last active timestamp after ${timeDiffInMillisecond} minutes`,
      'index.clearLastActiveTimestamp',
      { lastActiveTime, currentTimestamp: new Date(currentTimestamp) }
    );
    await storage.removeItem(LAST_ACTIVE_TIME);
  }

  static async clearVerifier() {
    const { default: helpers } = await import('../helpers');
    logger.info('clearing the code verifier', 'auth.clearVerifier', {
      verifier: helpers.getMaskedData(await storage.getItem(VERIFIER_KEY))
    });
    await storage.removeItem(VERIFIER_KEY);
  }

  auth0 = new auth0.WebAuth(config.auth0);

  // To check if currentTimestamp is greater than timeStampToCheck
  isTimePassed = ({
    currentTimestamp,
    timeStampToCheck,
    interval,
    durationType = 'ms'
  }) =>
    currentTimestamp.isAfter(
      moment(timeStampToCheck).add(interval, durationType)
    );

  // Check last_updated_timestamp and force update token
  checkAndUpdateToken = async () => {
    const lastUpdatedTimestamp =
      await this.constructor.getLastUpdatedTimestamp();
    const loggedInTimestamp = await this.constructor.getLogggedInTime();
    const currentTimestamp = moment();
    // Logout user if refresh token lifetime reached
    if (
      this.isTimePassed({
        currentTimestamp,
        timeStampToCheck: loggedInTimestamp,
        interval: refreshTokenLifeTime
      })
    ) {
      logger.info(
        'Refresh token lifetime reached. Logging out user. Session Expired Case 3',
        'auth.checkAndUpdateToken',
        { loggedInTimestamp, currentTimestamp }
      );
      this.logout(logoutReasons.expired);
      return;
    }

    // Renew Token if token timout reached
    if (
      this.isTimePassed({
        currentTimestamp,
        timeStampToCheck: lastUpdatedTimestamp,
        interval: tokenTimeoutDuration
      })
    ) {
      logger.info(
        `Updating access token after ${tokenTimeoutDuration} ms`,
        'auth.checkAndUpdateToken',
        { lastUpdatedTimestamp, currentTimestamp }
      );
      await this.renewAccessToken();
    }
  };

  getNewAccessToken = async (refreshToken) => {
    const { default: authApi } = await import('../../api/auth');
    const result = await authApi.fetchNewAccessToken(refreshToken);

    if (result && result.access_token && result.refresh_token) {
      await this.constructor.setLastUpdatedTimestamp(moment().format());
      await this.constructor.setAccessToken(result.access_token);
      await this.constructor.setIdToken(result.id_token);
      await this.constructor.setRefreshToken(result.refresh_token);
      const { default: helpers } = await import('../helpers');
      logger.info(
        'Successfully set new accessToken and refresh token',
        'auth.getNewAccessToken',
        {
          refreshToken: helpers.getMaskedData(result.refresh_token)
        }
      );
    } else if (result && result.status === 204) {
      logger.warn('Result from Auth Api is empty.', 'auth.getNewAccessToken');
      throw new Error('Empty result from Auth API');
    } else {
      logger.warn('Undefined Error from Auth API.', 'auth.getNewAccessToken');
      throw new Error('Undefined Error from Auth API');
    }
  };

  setAnonymousToken = async (anonymousToken) => {
    await this.constructor.setAccessToken(anonymousToken);
  };

  getAnonymousToken = async () => {
    await this.constructor.getAccessToken();
  };

  generateAccessToken = async () => {
    const refreshToken = await this.constructor.getRefreshToken();
    const loggedInTimestamp = await this.constructor.getLogggedInTime();
    const { default: helpers } = await import('../helpers');
    logger.info('Fetched session values', 'auth.generateAccessToken', {
      loggedInTimestamp,
      refreshToken: helpers.getMaskedData(refreshToken)
    });
    const currentTimestamp = moment();
    if (
      !isEmpty(refreshToken) &&
      !this.isTimePassed({
        currentTimestamp,
        timeStampToCheck: loggedInTimestamp,
        interval: refreshTokenLifeTime
      })
    ) {
      logger.info('Renewing access token', 'auth.generateAccessToken', {
        currentTimestamp
      });
      try {
        await retry(
          (bail) =>
            this.getNewAccessToken(refreshToken).catch(
              bailIfErrorNotRetryable(bail)
            ),
          getRetryConfig((err) =>
            logger.error(
              'Retrying to get access token after error.',
              'auth.generateAccessToken',
              {
                err
              }
            )
          )
        );
      } catch (error) {
        logger.error(
          'Could not fetch access token. Logging out',
          'auth.generateAccessToken',
          { error }
        );
        this.logout(logoutReasons.error);
      }
    } else {
      logger.error('Refresh token missing', 'auth.generateAccessToken');
      this.logout(logoutReasons.error);
    }
  };

  // Throttling to avoid multiple calls within refresh token reuse interval
  renewAccessToken = throttle(async () => {
    const status = await Network.getStatus();
    if (status.connected) {
      logger.info('Generating access token', 'auth.renewAccessToken', {
        currentTimestamp: moment()
      });
      await this.generateAccessToken();
    } else if (noConnectionQueue.size === 0) {
      // unlikely to get this log in logdna since user is offline
      logger.info(
        'User is offline, adding token refresh call to queue',
        'auth.renewAccessToken'
      );
      await noConnectionQueue.add(async () => {
        logger.info(
          'Connected to internet, renewing access token',
          'auth.renewAccessToken'
        );
        await this.generateAccessToken();
      });

      logger.info(
        'Stopping token inspection until network is reconnected',
        'auth.renewAccessToken'
      );
      clearInterval(this.tokenInspectionIntervalId);

      const networkListener = await Network.addListener(
        'networkStatusChange',
        async (networkStatus) => {
          logger.info('Network status changed', 'auth.renewAccessToken', {
            status: networkStatus
          });
          if (networkStatus.connected) {
            await networkListener.remove();
            if (isLoggedIn()) {
              await noConnectionQueue.start();
              await noConnectionQueue.onIdle();

              logger.info(
                'Restarting token inspection',
                'auth.renewAccessToken'
              );
              this.startTokenInspection();
            } else {
              logger.info(
                'Connected to internet but user is not logged in, clearing queue',
                'auth.renewAccessToken'
              );
              noConnectionQueue.clear();
            }
          }
        }
      );
    }
  }, refreshTokenReuseInterval);

  async renewAccessTokenIfExpired() {
    // PromiseQueue to make sure only one execution happens at a time in case of
    // simultaneous invocations from different sources
    await promiseQueue.add(async () => {
      const accessToken = await this.constructor.getAccessToken();
      if (isLoggedIn() && (!accessToken || this.isTokenExpired(accessToken))) {
        logger.info('Access token expired', 'auth.renewAccessTokenIfExpired');
        await this.renewAccessToken();
      }
    });
    await promiseQueue.onIdle();
  }

  login = (options, onExit = () => null) => {
    let ref;
    if (isNativePlatform) {
      const authUrl = this.auth0.client.buildAuthorizeUrl(options);
      const { isAndroid } = platformInfo;
      const browserOptions = `location=no,footer=no,toolbar=no,zoom=no,presentationstyle=overFullScreen${
        isAndroid ? ',hidden=yes' : ''
      }`;
      // TODO: Temporary fix to avoid black screen coming for android device
      ref = cordova.InAppBrowser.open(authUrl, '_blank', browserOptions);
      if (isAndroid) {
        ref.addEventListener('loadstop', () => {
          ref.show();
        });
      }
      const exitCallback = () => {
        logger.info(
          'SignIn/Login flow was cancelled or failed',
          'auth.redirectToUniversalLogin'
        );
        onExit();
      };

      const loadstartEventHandler = (event) => {
        const eventUrl = event.url;
        if (eventUrl.includes(`${config.deeplinkUrl}:///callback`)) {
          ref.hide();
          ref.close();
          ref.removeEventListener('exit', exitCallback);
          const callBackString = eventUrl.split('://').pop();
          history.push(callBackString);
        }
      };

      const messageEventHandler = (event) => {
        const { url } = get(event, 'data', {});
        if (url) {
          ref = cordova.InAppBrowser.open(url, '_system');
        }
        ref.addEventListener('message', messageEventHandler);
        ref.addEventListener('loadstart', loadstartEventHandler);
        ref.addEventListener('exit', exitCallback);
      };

      ref.addEventListener('message', messageEventHandler);
      ref.addEventListener('loadstart', loadstartEventHandler);
      ref.addEventListener('exit', exitCallback);
    } else {
      this.auth0.authorize(options);
    }
  };

  async loginWithSaml({ connection, challenge }) {
    const options = {
      connection,
      code_challenge: challenge,
      code_challenge_method: 'S256',
      nonce: 'NONCE',
      scope: config.auth0.scope,
      responseType: 'code',
      audience: config.auth0.audience
    };

    this.login(options);
  }

  async loginWithAetnaIam({ connection, challenge, branding, businessData }) {
    const options = {
      connection,
      code_challenge: challenge,
      code_challenge_method: 'S256',
      nonce: 'NONCE',
      scope: config.auth0.scope,
      responseType: 'code',
      audience: config.auth0.audience,
      display: branding,
      resource: businessData
    };

    this.login(options);
  }

  redirectToUniversalLogin = async ({
    accessCode,
    auth0Connection = null,
    isLogin,
    challenge,
    language,
    onExit,
    audienceType = null,
    connectionName = null
  }) => {
    const { isBrowser } = platformInfo;
    const { default: helpers } = await import('../helpers');
    const { getUserCompanyDetail } = helpers;
    const {
      companyDetail: { name }
    } = await getUserCompanyDetail();
    const analyticsSessionDetails = await storage.getItem(
      'analyticsSessionDetails'
    );

    const options = {
      brand: `{"id":"${config.brand}"}`,
      accessCode,
      isLogin,
      showHeader: true,
      code_challenge: challenge,
      code_challenge_method: 'S256',
      nonce: 'NONCE',
      scope: config.auth0.scope,
      responseType: 'code',
      prompt: 'login',
      audience: config.auth0.audience,
      audienceType,
      locale: language,
      sessionId: getSessionId(),
      isBrowser,
      analyticsSessionDetails,
      companyName: name,
      brandTag: config.analytics.tags[0],
      userAgent: get(window, 'navigator.userAgent', ''),
      appVersion
    };
    if (!isEmpty(auth0Connection)) {
      options.connection = auth0Connection;
    }
    if (!isEmpty(connectionName)) {
      options.connectionName = connectionName;
    }
    this.login(options, onExit);
  };

  logout = throttle((reason, redirectUrl) => {
    const logoutParams = { pathname: '/logout', search: '' };
    if (reason) {
      logoutParams.search = `?reason=${reason}`;
    }
    if (redirectUrl) {
      logoutParams.search += `${
        logoutParams.search ? '&' : '?'
      }redirectTo=${encodeURIComponent(redirectUrl)}`; // Encode the entire value of the redirectTo parameter
    }
    if (isLoggedIn()) {
      logger.info('Logging out user', 'auth.logout', { redirectUrl, reason });
      promiseQueue.clear();
      logger.info('Cleared Promise Queue', 'auth.logout');
    } else {
      logger.warn('Logout called when no user is logged in', 'auth.logout');
    }
    history.push(logoutParams);
  }, 5000);

  clearDataAndRedirect = async (urlParams) => {
    let returnTo = isNativePlatform ? '' : window.location.origin;

    if (!isEmpty(urlParams)) {
      returnTo += `?${stringify(urlParams)}`;
    }
    await this.clearData();
    if (isNativePlatform) {
      logger.info(
        'Logging out user on native platform',
        'auth.clearDataAndRedirect'
      );
      const ref = cordova.InAppBrowser.open(
        `https://${config.auth0.domain}/v2/logout?federated&client_id=${config.auth0.clientID}`,
        '_blank',
        'zoom=no,hidden=yes'
      );
      const interval = setInterval(() => {
        ref.executeScript({ code: 'document.readyState' }, (values) => {
          const documentState = values[0];
          if (documentState === 'complete') {
            clearInterval(interval);
            ref.close();
          }
        });
      }, 1000);
      history.push({ pathname: '/', search: returnTo });
    } else {
      const isExternalRflUser =
        getParsedValue(await storage.getItem('isExternalRflUser')) || false;
      logger.info('Logging out user on browser', 'auth.clearDataAndRedirect');

      if (isExternalRflUser) {
        const { default: helpers } = await import('../helpers');
        logger.info('External rfl user found', 'auth.clearDataAndRedirect');
        returnTo = await helpers.getRflUrl();
      }
      this.auth0.logout({
        returnTo,
        clientID: config.auth0.clientID,
        federated: isExternalRflUser
      });
    }
  };

  clearData = async () => {
    const twilioClient = getTwilioClient();
    logger.info('Clearing data from storage', 'auth.clearData');
    const { dispatch } = await import('../../store');
    dispatch(resetRootState());
    clearInterval(this.tokenInspectionIntervalId);
    await this.constructor.clearAccessToken();
    setAuth0IdFromToken();
    setUserDetails({});
    if (!isEmpty(twilioClient)) {
      await twilioClient.shutdown();
      setTwilioClient(null);
    }
    await this.constructor.clearIdToken();
    await this.constructor.clearAnalyticsSessionData();
    await this.constructor.clearRefreshToken();
    await this.constructor.clearLastUpdatedTimestamp();
    await this.constructor.clearLogggedInTime();
    await this.constructor.clearLastActiveTimestamp();
    await this.constructor.clearBreathingExerciseProgress();
    const { persistor: apolloPersistor } = await import(
      '../graphql/apollo-client'
    );
    await apolloPersistor.purge();
  };

  parseHash({ hash }, cb) {
    this.auth0.parseHash({ hash }, cb);
  }

  isTokenExpired(token) {
    const expirationDate = this.constructor.getTokenExpirationDate(token);
    return expirationDate < new Date();
  }

  async getUserDetails() {
    const accessToken = await this.constructor.getAccessToken();
    let userDetails = {};
    if (accessToken) {
      const decodedToken = decode(accessToken);
      let birthMonth = decodedToken[`${namespace}birth_month`];
      let birthDay = decodedToken[`${namespace}birth_day`];

      if (birthMonth && birthMonth.length === 1) {
        birthMonth = `0${birthMonth}`;
      }
      if (birthDay && birthDay.length === 1) {
        birthDay = `0${birthDay}`;
      }
      userDetails = {
        first_name: decodedToken[`${namespace}first_name`],
        last_name: decodedToken[`${namespace}last_name`],
        company_name: decodedToken[`${namespace}company_name`],
        gender: decodedToken[`${namespace}gender`],
        birth_year: decodedToken[`${namespace}birth_year`],
        birth_month: birthMonth,
        birth_day: birthDay,
        onboard_complete: decodedToken[`${namespace}onboard_complete`],
        name: decodedToken[`${namespace}name`],
        employer_id: decodedToken[`${namespace}employer_id`],
        employee_id: decodedToken[`${namespace}employee_id`],
        features: decodedToken[`${namespace}features`],
        phone: decodedToken[`${namespace}phone`],
        country_code: decodedToken[`${namespace}country_code`],
        country: decodedToken[`${namespace}country`],
        picture: decodedToken[`${namespace}picture`],
        roles: decodedToken[`${namespace}roles`],
        locale: decodedToken[`${namespace}locale`],
        connection: decodedToken[`${namespace}connection`],
        access_code: decodedToken[`${namespace}access_code`],
        md5Email: decodedToken[`${namespace}md5_email`],
        is_anonymous: decodedToken[`${namespace}is_anonymous`] || false,
        isMigrated: decodedToken.isMigrated || false,
        audienceType: decodedToken[`${namespace}audience_type`] || null
      };
      const eligibilityId = decodedToken[`${namespace}eligibility_id`];
      if (eligibilityId) userDetails.eligibility_id = eligibilityId;
      const idToken = await this.constructor.getIdToken();
      if (idToken) {
        const decodedIdToken = decode(idToken);
        userDetails.email = decodedIdToken.email;
      }
      return userDetails;
    }
    return {};
  }

  encodeURLInBase64 = (str) =>
    str
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

  getSHA256Hash = (buffer) =>
    crypto.createHash('sha256').update(buffer).digest();

  idTransfer = async (loginHint) => {
    const options = {
      connection: 'aetna-iam',
      scope: config.auth0.scope,
      responseType: 'code',
      audience: config.auth0.audience,
      display: 'RFL',
      login_hint: loginHint
    };
    this.login(options);
  };

  _getDetailsAndAuthenticate = async ({
    accessCode,
    companyObj,
    nextAction,
    activeLanguage,
    onExit = () => null
  }) => {
    try {
      const {
        connectionType,
        auth0_connection: auth0Connection,
        brandProps
      } = companyObj;
      await storage.setItem(storeKeys.ACCESS_CODE, accessCode, true);
      await storage.setItem(
        storeKeys.COMPANY_DETAILS,
        isNativePlatform ? companyObj : JSON.stringify(companyObj),
        true
      );
      const verifier = this.encodeURLInBase64(crypto.randomBytes(32));
      const { default: helpers } = await import('../helpers');
      logger.info(
        'Setting the code verifier in storage',
        'auth._getDetailsAndAuthenticate',
        { verifier: helpers.getMaskedData(verifier) }
      );
      await storage.setItem(VERIFIER_KEY, verifier);
      const audienceType = get(brandProps, 'audience_type', null);
      if (audienceType === audienceTypes.rfl) {
        await storage.setItem(storeKeys.AUDIENCE_TYPE, 'rfl', true);
      } else {
        await storage.setItem(storeKeys.AUDIENCE_TYPE, 'commercial', true);
      }
      const challenge = this.encodeURLInBase64(this.getSHA256Hash(verifier));
      const language = activeLanguage;
      if (connectionType === 'saml') {
        logger.info(
          'Redirecting to saml login',
          'auth._getDetailsAndAuthenticate'
        );
        const urlParams = new URLSearchParams(window.location.search);
        const hasAccessCode = urlParams.has('access_code');
        if (audienceType === audienceTypes.rfl && hasAccessCode) {
          await storage.setItem('isExternalRflUser', JSON.stringify(true));
        }
        this.loginWithSaml({
          connection: auth0Connection,
          challenge
        });
      } else if (connectionType === 'aetna-iam') {
        if (
          isEmpty(brandProps) ||
          isEmpty(brandProps.branding) ||
          isEmpty(brandProps.planSponsorId) ||
          isEmpty(brandProps.audience_type)
        ) {
          logger.error(
            'Required brand props are missing for aetna-iam connection',
            'auth._getDetailsAndAuthenticate',
            { brandProps }
          );
          throw new Error('Brand props are empty for aetna-iam connection');
        }

        logger.info(
          `Redirecting to aetna IAM login for audience type : ${brandProps.audience_type}`,
          'auth._getDetailsAndAuthenticate'
        );

        this.loginWithAetnaIam({
          connection: auth0Connection,
          challenge,
          branding: brandProps.branding,
          businessData: `memberInsurer~${brandProps.planSponsorId}`
        });
      } else if (connectionType === 'passwordless') {
        this.redirectToUniversalLogin({
          accessCode,
          connectionName: auth0Connection,
          isLogin: !nextAction || nextAction.toLowerCase() === 'login',
          challenge,
          language,
          onExit
        });
      } else {
        logger.info(
          'Redirecting to universal login',
          'auth._getDetailsAndAuthenticate'
        );
        this.redirectToUniversalLogin({
          accessCode,
          auth0Connection,
          isLogin: !nextAction || nextAction.toLowerCase() === 'login',
          challenge,
          language,
          audienceType: get(brandProps, 'audience_type', null),
          onExit
        });
      }
    } catch (error) {
      logger.error(
        'Error occurred while getting the authentication details',
        'auth._getDetailsAndAuthenticate'
      );
    }
  };

  tokenInspectionIntervalId = null;

  // Set interval for token updation
  startTokenInspection = () => {
    this.tokenInspectionIntervalId = setInterval(async () => {
      await promiseQueue.add(this.checkAndUpdateToken);
      await promiseQueue.onIdle();
    }, tokenInspectionInterval);
  };
}

export default new Auth();
export const authService = Auth;
export * from './helpers';
