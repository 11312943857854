import {
  HIDE_REDIRECTION_POPUP,
  SHOW_PUSH_POPUP,
  SHOW_REDIRECTION_POPUP
} from '../actions/types';

const INITIAL_STATE = {
  showRedirectionPopup: false,
  showPushPopup: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HIDE_REDIRECTION_POPUP:
      return {
        ...state,
        showRedirectionPopup: false
      };
    case SHOW_REDIRECTION_POPUP:
      return {
        ...state,
        showRedirectionPopup: true
      };
    case SHOW_PUSH_POPUP:
      return {
        ...state,
        showPushPopup: true
      };
    default:
      return state;
  }
};
